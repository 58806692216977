import React, { useEffect, useState } from 'react'
import { RiVipDiamondFill } from "react-icons/ri";
import CollectionViewProduct from './Collections/CollectionViewProduct';
import royalaffairimage1 from '../Assets/collection/Royal Affair/collectionimg1.jpg'
import royalaffairimage2 from '../Assets/collection/Royal Affair/collectionimage2.jpg'
import nobleessentialimg1 from '../Assets/collection/Noble Essential/collectionimage4.jpg'
import nobleessentialimg2 from '../Assets/collection/Noble Essential/collectionimage3.jpg'
import luxurysolitireimg1 from '../Assets/collection/Luxury solitire/collectionimage5.jpg'
import luxurysolitireimg2 from '../Assets/collection/Luxury solitire/collectionimage6.jpg'
import majesticmomentsimg1 from '../Assets/collection/Majestic moments/collectionimage7.jpg'
import majesticmomentsimg2 from '../Assets/collection/Majestic moments/collectionimage8.jpg'
import eternalvowsimg1 from '../Assets/collection/Eternal vows/collectionimage9.jpg'
import eternalvowsimg2 from '../Assets/collection/Eternal vows/EV2.jpeg'
import Arrowleft from '../Assets/icons/arrowleft.svg'
import { IconButton } from '@mui/material'
import { useLocation, useNavigate } from 'react-router-dom';


const Collection = () => {
    const navigate=useNavigate()
    const location=useLocation()
    const pathid=location.state?.id
    const[product,setProduct]=useState(null)

    useEffect(()=>{
        if(pathid){
            handleToCollectionsection(pathid)
        }
    },[pathid])

    const handleToCollectionsection = (id) => {
        setTimeout(() => {
            const collectionElement=document.getElementById(`collection_section${id}`)
            if(collectionElement){
                collectionElement.scrollIntoView({behavior:'smooth'})
            }
        }, 0);       
  };

    const navigateToProduct = (newProduct) => {
        setProduct(newProduct);
        window.scrollTo(0, 0);  // Scrolls to the top of the page
    };
    const handleBackToCollection = () => {
        setProduct(null);  
      };
  return (
// {/* <>
//         {product===null ?( */}
        <div className='collection_container'>
        <div className='collection_main'>     

        <h3>OUR COLLECTION</h3>
        <section id='collection_section1' className='collection_section'>

        <div className='collection_img_container'>     
            <div className='image1'>
                <img src={royalaffairimage1} alt='' />
            </div>         
            <div className='image2'>
                <img src={royalaffairimage2} alt='' />
            </div>
            
        </div>

        <div className='collection_content'>
            <h4>ROYAL ESSENTIALS</h4>
            <p>Elevate your everyday elegance with pieces that seamlessly blend royal charm with daily wearability</p>
            <button onClick={()=>{
                navigateToProduct(1)
                navigate(`../collectionview/${1}`,{state:{from:'collection'}})
                }} className='collection_button'>DISCOVER DAILY WEAR</button>
        </div>
        </section>

        <div className='collection-line'>
            <RiVipDiamondFill className='diamond-Icon1'/>
            <hr />
            <RiVipDiamondFill className='diamond-Icon2'/>
        </div>



        <section id='collection_section2' className='collection_section rev-section'>
            <div className='collection_content'>
                    <h4>NOBLE AFFAIR</h4>
                    <p>Essential pieces that exude sophistication, designed for those who carry themselves with quite confidence and grace</p>
                    <button onClick={()=>{
                        navigateToProduct(2)
                        navigate(`../collectionview/${2}`,{state:{from:'collection'}})
                        }} className='collection_button'>DISCOVER ELEVATED ESSENTIALS</button>
            </div>
            <div className='collection_img_container'>
            <div className='image1'>
                            <img src={nobleessentialimg2} alt=''/>
                        </div>
                        <div className='image2'>
                        <img src={nobleessentialimg1} alt=''/>
                        </div>
                    </div>
        </section>

        <div className='collection-line'>
            <RiVipDiamondFill className='diamond-Icon1'/>
            <hr />
            <RiVipDiamondFill className='diamond-Icon2'/>
        </div>

        <section id='collection_section3' className='collection_section'>
        <div className='collection_img_container'>
                        <div className='image1'>
                            <img src={luxurysolitireimg1} alt=''/>
                        </div>
                        <div className='image2'>
                            <img src={luxurysolitireimg2} alt=''/>
                        </div>
                    </div>
                    <div className='collection_content'>
                    <h4>LUXURY SOLITAIRE</h4>
                    <p>Light,delicate pieces that add a touch of royal allure to more intimate,refined gatherings</p>
                    <button onClick={()=>{
                        navigateToProduct(3)
                         navigate(`../collectionview/${3}`,{state:{from:'collection'}})
                         }} className='collection_button'>DISCOVER FOR LIGHT OCCASIONS</button>
                </div>
        </section>
        
        <div className='collection-line'>
            <RiVipDiamondFill className='diamond-Icon1'/>
            <hr />
            <RiVipDiamondFill className='diamond-Icon2'/>
        </div>

        <section id='collection_section4' className='collection_section rev-section'>
        <div className='collection_content'>
                    <h4>MAJESTIC MOMENTS</h4>
                    <p>For the grand occasions that deserves nothing less than the most opulent and striking designs.</p>
                    <button onClick={()=>{
                        navigateToProduct(4)  
                        navigate(`../collectionview/${4}`,{state:{from:'collection'}})
                        }} className='collection_button'>DISCOVER FOR HEAVY OCCASIONS</button>
                </div>
                <div className='collection_img_container'>
                        <div className='image1'>
                            <img src={majesticmomentsimg1} alt=''/>
                        </div>
                        <div className='image2'>
                            <img src={majesticmomentsimg2} alt=''/>
                        </div>
                    </div>
        </section>

        <div className='collection-line'>
            <RiVipDiamondFill className='diamond-Icon1'/>
            <hr />
            <RiVipDiamondFill className='diamond-Icon2'/>
        </div>

        <section id='collection_section5' className='collection_section'>
        <div className='collection_img_container'>
                        <div className='image1'>
                            <img src={eternalvowsimg1} alt=''/>
                        </div>
                        <div className='image2'>
                            <img src={eternalvowsimg2} alt=''/>
                        </div>
                    </div>
                <div className='collection_content'>
                    <h4>ETERNAL VOWS</h4>
                    <p>Bridal collections that celebrate love with the timeless elegance and grandeur befitting a royal union</p>
                    <button onClick={()=>{
                        navigateToProduct(5)  
                        navigate(`../collectionview/${5}`,{state:{from:'collection'}})
                        }} className='collection_button'>DISCOVER BRIDAL WEAR </button>
                </div>
        </section>
        </div>
        </div>
        // )
        // :(  
        //     <div className='product_view'>
        //     <IconButton className='home_iconbutton' id='collection_back_btn' onClick={handleBackToCollection}>
        //       <img src={Arrowleft}  alt='' />
        //     </IconButton>
        //     <CollectionViewProduct productid={product} />
        //   </div>
        //     )}
        //     </>
  )
}

export default Collection

