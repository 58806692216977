// import img1 from '../Assets/collection/2.JPG';
// import img2 from '../Assets/collection/homesubimage5.jpeg';

import REInner1 from  '../Assets/collection/Royal Affair/RE_inner_1.jpg'
import REInner2 from  '../Assets/collection/Royal Affair/collectionimg1.jpg'
import REInner3 from  '../Assets/collection/Royal Affair/collectionimage2.jpg'

import NAInner1 from  '../Assets/collection/Noble Essential/NA_inner_1.jpg'
import NAInner2 from  '../Assets/collection/Noble Essential/NA_inner_2.jpg'

import EVInner1 from  '../Assets/collection/Eternal vows/EV_inner_1.jpg'
import EVInner2 from  '../Assets/collection/Eternal vows/EV_inner_2.jpg'

import MMInner1 from  '../Assets/collection/Majestic moments/MM_inner_1.jpg'
import MMInner2 from  '../Assets/collection/Majestic moments/MM_inner_2.jpg'
import MMInner3 from  '../Assets/collection/Majestic moments/MM_inner_3.jpg'

import LSInner1 from  '../Assets/collection/Luxury solitire/LS_inner_1.jpg'
import LSInner2 from  '../Assets/collection/Luxury solitire/LS_inner_2.jpg'

import blog1_Img from '../Assets/category/blog_main.jpg'



import img3 from '../Assets/category/4.jpg'
import img4 from '../Assets/category/jumka/1.jpg'
import img5 from '../Assets/category/jumka/2.jpg'
import img6 from '../Assets/category/jumka/3.jpg'
import img7 from '../Assets/category/Rings/1.jpg'
import img8 from '../Assets/category/Rings/2.jpg'
import img9 from '../Assets/category/Rings/3.jpg'
import img10 from '../Assets/category/Rings/4.jpg'
import img11 from '../Assets/category/Nose pin/1.jpg'
import img12 from '../Assets/category/Nose pin/2.jpg'
import img13 from '../Assets/category/Nose pin/3.jpg'
import img14 from '../Assets/category/Nose pin/4.jpg'
import img15 from '../Assets/category/solitireearrings/1.jpg'
import img16 from '../Assets/category/solitireearrings/2.jpg'
import img17 from '../Assets/category/solitireearrings/3.jpg'
import img18 from '../Assets/category/solitireearrings/6.jpg'
import img19 from '../Assets/category/Bangles/1.jpg'
import img20 from '../Assets/category/Bangles/2.jpg'
import img21 from '../Assets/category/Bangles/3.jpg'
import img22 from '../Assets/category/Bangles/4.jpg'
import Jsubimg1 from '../Assets/category/jumka/jsub1/1.jpg'
import Jsubimg2 from '../Assets/category/jumka/jsub1/2.jpg'
import Jsubimg3 from '../Assets/category/jumka/jsub1/3.jpg'
import Jsubimg4 from '../Assets/category/jumka/jsub2/1.jpg'
import Jsubimg5 from '../Assets/category/jumka/jsub2/2.jpg'
import Jsubimg6 from '../Assets/category/jumka/jsub2/3.jpg'
import Jsubimg7 from '../Assets/category/jumka/jsub3/1.jpg'
import Jsubimg8 from '../Assets/category/jumka/jsub4/1.jpg'
import Jsubimg9 from '../Assets/category/jumka/jsub4/2.jpg'
import Jsubimg10 from '../Assets/category/jumka/jsub4/3.jpg'
import rsubimg11 from '../Assets/category/Rings/rsub1/1.jpg'
import rsubimg12 from '../Assets/category/Rings/rsub1/2.jpg'
import rsubimg13 from '../Assets/category/Rings/rsub2/1.jpg'
import rsubimg14 from '../Assets/category/Rings/rsub2/2.jpg'
import rsubimg15 from '../Assets/category/Rings/rsub3/1.jpg'
import rsubimg16 from '../Assets/category/Rings/rsub3/2.jpg'
import rsubimg17 from '../Assets/category/Rings/rsub4/1.jpg'
import rsubimg18 from '../Assets/category/Rings/rsub4/2.jpg'
import Nsubimg19 from '../Assets/category/Nose pin/npsub1/1.jpg'
import Nsubimg20 from '../Assets/category/Nose pin/npsub1/2.jpg'
import Nsubimg21 from '../Assets/category/Nose pin/npsub1/3.jpg'
import Nsubimg22 from '../Assets/category/Nose pin/nsub2/1.jpg'
import Nsubimg23 from '../Assets/category/Nose pin/nsub2/2.jpg'
import Nsubimg24 from '../Assets/category/Nose pin/nsub2/3.jpg'
import Nsubimg25 from '../Assets/category/Nose pin/npsub3/1.jpg'
import Nsubimg26 from '../Assets/category/Nose pin/npsub3/2.jpg'
import Nsubimg27 from '../Assets/category/Nose pin/npsub3/3.jpg'
import Nsubimg28 from '../Assets/category/Nose pin/npsub4/1.jpg'
import Nsubimg29 from '../Assets/category/Nose pin/npsub4/2.jpg'
import Nsubimg30 from '../Assets/category/Nose pin/npsub4/3.jpg'
import sesubimg31 from '../Assets/category/solitireearrings/sesub1/1.jpg'
import sesubimg32 from '../Assets/category/solitireearrings/sesub1/2.jpg'
import sesubimg33 from '../Assets/category/solitireearrings/sesub1/3.jpg'
import sesubimg34 from '../Assets/category/solitireearrings/sesub2/1.jpg'
import sesubimg35 from '../Assets/category/solitireearrings/sesub2/2.jpg'
import sesubimg36 from '../Assets/category/solitireearrings/sesub2/3.jpg'
import sesubimg37 from '../Assets/category/solitireearrings/sesub3/1.jpg'
import sesubimg38 from '../Assets/category/solitireearrings/sesub3/2.jpg'
import sesubimg39 from '../Assets/category/solitireearrings/sesub3/3.jpg'
import sesubimg40 from '../Assets/category/solitireearrings/sesub4/1.jpg'
import sesubimg41 from '../Assets/category/solitireearrings/sesub4/2.jpg'
import sesubimg42 from '../Assets/category/solitireearrings/sesub4/3.jpg'
import bsubimg43 from '../Assets/category/Bangles/bsub1/1.jpg'
import bsubimg44 from '../Assets/category/Bangles/bsub1/2.jpg'
import bsubimg45 from '../Assets/category/Bangles/bsub1/3.jpg'
import bsubimg46 from '../Assets/category/Bangles/bsub2/1.jpg'
import bsubimg47 from '../Assets/category/Bangles/bsub2/2.jpg'
import bsubimg48 from '../Assets/category/Bangles/bsub3/1.jpg'
import bsubimg49 from '../Assets/category/Bangles/bsub3/2.jpg'
import bsubimg50 from '../Assets/category/Bangles/bsub4/1.jpg'
import bsubimg51 from '../Assets/category/Bangles/bsub4/2.jpg'


export const products=[
    {id:1,path:REInner1,subimage:[{img:REInner1},{img:REInner2},{img:REInner3}]},
    {id:2,path:NAInner1,subimage:[{img:NAInner1},{img:NAInner2}]},
    {id:3,path:LSInner1,subimage:[{img:LSInner1},{img:LSInner2}]},
    {id:4,path:MMInner1,subimage:[{img:MMInner1},{img:MMInner2},{img:MMInner3}]},
    {id:5,path:EVInner1,subimage:[{img:EVInner1},{img:EVInner2}]},
]

export const categorylevelimages=[
    {id:1,path:img3,type:'EARRINGS',name:'A Collection of Stunning Diamond Gold Earrings',subimage:[{img:img3},{img:Jsubimg1},{img:Jsubimg2},{img:Jsubimg3}]},
    {id:2,path:img6,type:'EARRINGS',name:'Diamond Gold Earrings for Every Occasion',subimage:[{img:img6},{img:Jsubimg4},{img:Jsubimg5},{img:Jsubimg6}]},
    {id:3,path:img5,type:'EARRINGS',name:'Elevate Your Look with Diamond Gold Earrings ensuring a luxurious and sparkling',subimage:[{img:img5},{img:Jsubimg7},{img:img5},{img:Jsubimg7}]},
    {id:4,path:img4,type:'EARRINGS',name:'Diamond Gold Drop and Dangling Earrings',subimage:[{img:img4},{img:Jsubimg8},{img:Jsubimg9},{img:Jsubimg10}]},
    {id:5,path:img7,type:'RINGS',name:'Elevate your style with a stunning diamond gold ring',subimage:[{img:img7},{img:rsubimg11},{img:rsubimg12},{img:img7}]},
    {id:6,path:img8,type:'RINGS',name:'A Guide to Choosing the Right Diamond Gold Ring',subimage:[{img:img8},{img:rsubimg13},{img:rsubimg14},{img:img8}]},
    {id:7,path:img9,type:'RINGS',name:'Sparkling Symbol of Love with Diamond Gold Wedding Rings',subimage:[{img:img9},{img:rsubimg15},{img:rsubimg16},{img:img9}]},
    {id:8,path:img10,type:'RINGS',name:'Bold and Beautiful Diamond Gold Designs',subimage:[{img:img10},{img:rsubimg17},{img:rsubimg18},{img:img10}]},
    {id:9,path:img11,type:'NOSE PINS',name:'A Touch of Tradition with Exquisite Diamond Gold Nose Pins',subimage:[{img:img11},{img:Nsubimg19},{img:Nsubimg20},{img:Nsubimg21}]},
    {id:10,path:img12,type:'NOSE PINS',name:'Modern Meets Traditional Diamond Gold Nose Pin',subimage:[{img:img12},{img:Nsubimg22},{img:Nsubimg23},{img:Nsubimg24}]},
    {id:11,path:img13,type:'NOSE PINS',name:'A World of Diamond Gold nose pinfor Every Occasion',subimage:[{img:img13},{img:Nsubimg25},{img:Nsubimg26},{img:Nsubimg27}]},
    {id:12,path:img14,type:'NOSE PINS',name:'Diamond Gold nose pin that Speaks to Your Soul',subimage:[{img:img14},{img:Nsubimg28},{img:Nsubimg29},{img:Nsubimg30}]},
    {id:13,path:img15,type:'SOLITAIRE EARRINGS',name:'Elevate Your Style with STUD earring Luxurious Piece ',subimage:[{img:img15},{img:sesubimg31},{img:sesubimg32},{img:sesubimg33}]},
    {id:14,path:img16,type:'SOLITAIRE EARRINGS',name:'A Guide to Choosing the Right Diamond Gold Stud Earrings',subimage:[{img:img16},{img:sesubimg34},{img:sesubimg35},{img:sesubimg36}]},
    {id:15,path:img17,type:'SOLITAIRE EARRINGS',name:'A pair of classic diamond gold stud earrings',subimage:[{img:img17},{img:sesubimg37},{img:sesubimg38},{img:sesubimg39}]},
    {id:16,path:img18,type:'SOLITAIRE EARRINGS',name:'The Timeless Beauty of Diamond Gold Stud Earrings',subimage:[{img:img18},{img:sesubimg40},{img:sesubimg41},{img:sesubimg42}]},
    {id:17,path:img19,type:'BANGLES',name:'Wrap Yourself in Luxury A Collection of Diamond Gold Bangles',subimage:[{img:img19},{img:bsubimg43},{img:bsubimg44},{img:bsubimg45}]}, 
    {id:18,path:img20,type:'BANGLES',name:'A Guide to Choosing the Perfect Style',subimage:[{img:img20},{img:bsubimg46},{img:bsubimg47},{img:img20}]}, 
    {id:19,path:img21,type:'BANGLES',name:'Timeless Elegance with Diamond Gold bangle ',subimage:[{img:img21},{img:bsubimg48},{img:bsubimg49},{img:img21}]},
    {id:20,path:img22,type:'BANGLES',name:'Experience the timeless elegance of diamond gold bangles',subimage:[{img:img22},{img:bsubimg50},{img:bsubimg51},{img:img22}]},            
]

export const blogcontent=[
    {id:1,path:blog1_Img,
        mainheading:'A Crown for Every Occasion: Choosing the Perfect Diamond for Life’s Milestones with Tri-Dia',
        maincontent: [
            `Life is a series of unforgettable moments, each one as unique and personal as the next.
Whether it’s the excitement of a proposal, the joy of an anniversary, or the quiet pride in a
personal achievement, these moments deserve to be celebrated in ways that last forever.
And what better way to capture those memories than with a diamond—a symbol of eternity,
love, and success?`,
            `At Tri-Dia, we believe that every milestone deserves its own crown. Our collection of
lab-grown diamonds offers a wide range of choices, each piece a reminder that life’s
biggest moments are worth cherishing for a lifetime. When you choose Tri-Dia, you’re
selecting more than just a diamond—you’re choosing a timeless treasure crafted with
precision, sustainability, and luxury in mind.`
          ],
        subcontent:[
        {subheading:'The Perfect Engagement Ring: A Promise Set in Stone with Tri-Dia',
         content:[`Your love story is one of a kind. So, when it’s time to ask that all-important question, the ring
should reflect that uniqueness. Choosing the perfect engagement ring is more than picking a
stone—it’s about capturing the essence of your relationship. Is your partner drawn to
timeless elegance, or do they lean toward something modern and bold?`,
            `At Tri-Dia, we offer a variety of shapes and settings, from the classic round to the sharp
elegance of a princess cut. Each diamond is crafted with precision and care, just like the
love you’re celebrating. It’s not just about finding a ring—it’s about finding the ring. With
Tri-Dia, you’ll discover a ring that forever symbolises the start of your journey together.`]},

        {subheading:'Anniversaries: Celebrating Love that Stands the Test of Time with Tri-Dia',
        content:[`Time has a way of adding layers to love. With every anniversary, there are new memories,
new stories, and new reasons to celebrate. Whether it’s your first anniversary or your fiftieth,
it’s a moment to reflect on how far you’ve come together`,
                `For these special occasions, you may want something that feels just as unique as the love
you share. Consider a Tri-Dia diamond that mirrors the strength and depth of your
relationship—perhaps a larger stone to mark the years that have passed, or a coloured
diamond to reflect the vibrant journey you’ve shared. Our collection includes stunning
options in rare hues, perfect for an anniversary gift that tells your story without words. With
Tri-Dia, celebrate the bond that grows stronger each year with a diamond as enduring as
your love.`]},

        {subheading:'Career Milestones: Celebrating Success with Tri-Dia',
        content:[`We often think of diamonds as symbols of love, but they are also powerful symbols of
success. There are few feelings as gratifying as achieving a major career goal—whether it’s
a promotion, landing a dream job, or hitting a personal milestone you’ve been striving toward
for years.`,
                `For moments like these, you deserve a piece that not only reflects your hard work but also
your ambition and resilience. A bold Tri-Dia diamond, like an emerald or marquise cut,
speaks to the confidence you’ve earned. At Tri-Dia, we understand that career milestones
are just as personal as life’s other major moments. Our diamonds are more than just
accessories—they are symbols of your journey, your victories, and the bright future ahead.`]},

        {subheading:'Personal Milestones: A Celebration of Self with Tri-Dia',
        content:[`Not all milestones are shared with others—some are deeply personal. Whether it’s
graduating from university, starting a new chapter in life, or simply taking time to
acknowledge your own growth, these moments deserve recognition.`,
            `When choosing a diamond to celebrate yourself, don’t shy away from selecting something
that speaks to your unique style and personality. A Tri-Dia necklace that you can wear
every day as a reminder of your strength, or a pair of elegant earrings that catch the light just
so—they’re more than just jewellery. They are symbols of your own progress and how far
you’ve come. Tri-Dia offers a variety of custom sizes and styles to ensure that your diamond
feels like an extension of who you are. Because celebrating yourself should be as special as
any other milestone.`]},

        {subheading:'Welcoming New Life or a New Legacy with Tri-Dia',
            content:[`Few moments in life are as transformative as bringing a new life into the world. The birth of a
child is a moment of pure joy and immense love—a moment that deserves to be marked with
something that will last for generations.`,
                `A Tri-Dia diamond gifted at the birth of a child becomes an heirloom, a piece of family
history that can be passed down through the years. It’s more than just a gift—it’s a way to
keep the memory of this beautiful moment alive, shared with future generations. Our
heirloom-quality Tri-Dia diamonds are crafted with the care and precision that such a
moment deserves. Whether it’s a ring, a necklace, or a bracelet, it’s a piece that will not only
celebrate the present but also connect you to the future.`]},

        {subheading:'Why Choose Tri-Dia?',
            content:[`Life’s milestones are fleeting, but the memories last forever. At Tri-Dia, we believe that those
moments deserve to be celebrated with diamonds that are as unique and meaningful as the
memories themselves. Our lab-grown diamonds offer the perfect blend of luxury and
sustainability, crafted to last through every chapter of your story`,
                `So whether it’s a love story, a personal triumph, or a family celebration, Tri-Dia is here to
help you crown each moment with a diamond that will forever remind you of the milestones
that matter most. Crown your next milestone with Tri-Dia. Explore our collection and find the
perfect diamond for your journey with Tri-Dia—where every moment is crowned with
brilliance.`]}
    ]},
]

