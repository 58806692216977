import React from 'react'
import img1 from '../Assets/category/6.jpg'
import img2 from '../Assets/category/4.jpg'
import img3 from '../Assets/category/3.jpg'
import img4 from '../Assets/category/5.jpg'
import img5 from '../Assets/category/2.jpg'
import { useNavigate } from 'react-router-dom'

const Category = () => {
    const navigate=useNavigate()
  return (
    <section className='category_section1'>
        <h3>CATEGORIES</h3>
        <div className='categories'>
        <div className='category_item' onClick={()=>navigate('/subcategory1/RINGS')}>
            <img  src={img3} alt='' className='category_img'/>
            <h4>RINGS</h4>
        </div>
        <div className='category_item' onClick={()=>navigate('/subcategory1/EARRINGS')}>
            <img src={img2} alt='' className='category_img'/>
            <h4>EARRINGS</h4>

        </div>
        <div className='category_item' onClick={()=> navigate(`/subcategory1/${'NOSE PINS'.replace(/\s+/g,'-')}`)}>
            <img src={img5} alt='' className='category_img'/>
            <h4>NOSE PINS</h4>
        </div>
        <div className='category_item' onClick={()=>navigate(`/subcategory1/${'SOLITAIRE EARRINGS'.replace(/\s+/g,'-')}`)}>
            <img src={img4}  alt='' className='category_img'/>
            <h4>SOLITAIRE EARRINGS</h4>
        </div>
        <div className='category_item' onClick={()=>navigate('/subcategory1/BANGLES')}>
            <img src={img1} alt='' className='category_img'/>
            <h4>BANGLES</h4>
        </div>
        </div>

</section>
  )
}

export default Category
