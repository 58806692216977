import React from 'react'
import { blogcontent } from '../Products'

const Bloglevel1 = (props) => {
    const filterdcontent=blogcontent.filter(item=>item.id===props.product)
    
  return (
    <>
        {filterdcontent && filterdcontent.map((item)=>(
            <div className='bloglevel1_container'>
                    <div className='blog1_img_container'>
                        <img src={item.path} alt=''/>
                    </div>
                    {item.subcontent.map((inneritem,index)=>(
                    <div className='blog_content_div'>
                        <h1>{inneritem.subheading}</h1>
                        {inneritem.content.map((inneritem2,innerindex)=>(
                            <p key={innerindex}>{inneritem2}</p>
                        ))}
                    </div>
                    ))}
            </div>
        ))}
    </>
  )
}

export default Bloglevel1
