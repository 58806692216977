import './App.css';
import About from './Components/About';
import Blog from './Components/Blog';
import Category from './Components/Category';
import Categorylevel1 from './Components/Category/Categorylevel1';
import Categorylevel3 from './Components/Category/Categorylevel3';
import Collection from './Components/Collection';
import CollectionViewProduct from './Components/Collections/CollectionViewProduct';
import ContactUs from './Components/ContactUs';
import Home from './Components/Home';
import Index from './Components/Index';
import { BrowserRouter,Routes,Route, Navigate } from 'react-router-dom';

function App() {
  return (
    <div>
      <BrowserRouter>
          <Routes>
            <Route path='/' element={<Index/>}>
              <Route path='/' element={<Navigate to='home' />} />

              <Route path='home' element={<Home/>}/>
                 <Route path='collectionview/:id?' element={<CollectionViewProduct/>}/>
             

              <Route path='about' element={<About/>}/>
              <Route path='contact' element={<ContactUs/>}/>
              <Route path='collection' element={<Collection/>}/>
              <Route path='category' element={<Category/>}/>
              <Route path='subcategory1/:name' element={<Categorylevel1/>}/>
              <Route path='blog' element={<Blog/>}/>
             </Route>
             <Route path='subcategory3/:id' element={<Categorylevel3/>}/>
          </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
