import React, { useState } from 'react'
import Arrowright from '../../Assets/icons/arrowright.svg'
import Arrowleft from '../../Assets/icons/arrowleft.svg'
import { IconButton } from '@mui/material'
import { useLocation, useNavigate } from 'react-router-dom'
import { categorylevelimages } from '../Products'
import { IoCloseCircle } from "react-icons/io5";

const Categorylevel3 = () => {

    const location=useLocation()
    const navigate = useNavigate(); 

    const productid = location.pathname.split('/subcategory3/')[1];

    const data=categorylevelimages.find(item=>item.id===parseInt(productid))

    const [displayimage,setdisplayimage]=useState(0)

  const clickRightButton=()=>{
    if (displayimage === 3) {
      setdisplayimage(0);
    } else if (displayimage >= 0 && displayimage < 3) {
      setdisplayimage(displayimage + 1);
    }
  }
  

  const clickLeftButton=()=>{
    if(displayimage===0){
      setdisplayimage(3)
    }
    else if (displayimage > 0 && displayimage <= 3) {
      setdisplayimage(displayimage - 1);
    } 
  }
    
    
    
  return (
    <div className='category_level3_main'>
          <IconButton className='category_closebtn' onClick={()=>navigate(-1)}>
            <IoCloseCircle/>
          </IconButton>
          <IconButton className='home_iconbutton' onClick={clickLeftButton}>
                    <img src={Arrowleft} alt=''/>
            </IconButton>
          <div className='category_level3_subdivmain'>
            <div className='category_level3_sub1'>
                <img alt='' src={`${data.subimage[displayimage].img}`} />
            </div>
            <div className='category_level3_sub2'>
                {data && data.subimage.map(item=>(
                    <img src={item.img} className='category_level3_subimg' alt=''/>
                ))}
                {/* <div>

                </div>
                <div>

                </div>
                <div>

                </div> */}
            </div>
        </div>
        <IconButton className='home_iconbutton' onClick={clickRightButton}>
                    <img src={Arrowright} alt=''/>
            </IconButton>
    </div>
  )
}

export default Categorylevel3
