import React from 'react'
import headerimage from '../Assets/ourstoryimages/headimagetransparent.png'
import section2image from '../Assets/ourstoryimages/section2image.jpeg'
import mehataimage from '../Assets/mehtaprofile/mehtaimage.png'
import uspicon1 from '../Assets/icons/Group 1407.png'
import uspicon2 from '../Assets/icons/Group 1408.png'
import uspicon3 from '../Assets/icons/Group 1409.png'
import uspicon4 from '../Assets/icons/Group 1410.png'
import certificateicon1 from '../Assets/icons/sgl.png'
import certificateicon2 from '../Assets/icons/igl.png'
import section3image from '../../src/Assets/ourstoryimages/MaskGroup69.png'
import { RiVipDiamondFill } from "react-icons/ri";

// '../src/Assets/ourstoryimages/MaskGroup69.png'

const About = () => {
  return (
    <div>
        {/*  */}
        <section className='about_section1_main'>
         <div className='about_section1' >
            <div className='about_bg_div'>
            <img src={headerimage} alt='' className='about_bg_img'/>
            </div>
            <div className='about_section1_content_maindiv'>
                <p>At Tri Dia, we understand that people often wait for the perfect moment to celebrate their achievements, only to find that time has slipped away and the opportunity has passed. We believe that while traditional diamonds are symbols of the past, lab-grown diamonds are intimate companions to your most cherished moments. Our exquisitely crafted diamond jewelry is designed to honor your triumphs and reflect your unique journey, allowing you to celebrate with a royal elegance that befits your achievements.</p>
            </div>
        </div>
        </section>
        
        <section className='about_section2'>
            <div className='about_section2_content'>

                <div className='about_section2_item'>
                    <svg width="32px" height="32px" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                        <g>
                            <path fill="none" d="M0 0h24v24H0z"/>
                            <path d="M4.873 3h14.254a1 1 0 0 1 .809.412l3.823 5.256a.5.5 0 0 1-.037.633L12.367 21.602a.5.5 0 0 1-.734 0L.278 9.302a.5.5 0 0 1-.037-.634l3.823-5.256A1 1 0 0 1 4.873 3z" fill='#FFF'/>
                        </g>
                    </svg>
                  <h2>TRILOKESH GEMS AND JEWELLERY PRIVATE LIMITED</h2>
                </div>

                <div className='about_section2_item'>
                <svg width="32px" height="32px" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                        <g>
                            <path fill="none" d="M0 0h24v24H0z"/>
                            <path d="M4.873 3h14.254a1 1 0 0 1 .809.412l3.823 5.256a.5.5 0 0 1-.037.633L12.367 21.602a.5.5 0 0 1-.734 0L.278 9.302a.5.5 0 0 1-.037-.634l3.823-5.256A1 1 0 0 1 4.873 3z" fill='#FFF'/>
                        </g>
                    </svg>
                  <h2>LAB-DIAMONDS ARE GROWN INHOUSE</h2>
                </div>

                <div className='about_section2_item'>
                <svg width="32px" height="32px" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                        <g>
                            <path fill="none" d="M0 0h24v24H0z"/>
                            <path d="M4.873 3h14.254a1 1 0 0 1 .809.412l3.823 5.256a.5.5 0 0 1-.037.633L12.367 21.602a.5.5 0 0 1-.734 0L.278 9.302a.5.5 0 0 1-.037-.634l3.823-5.256A1 1 0 0 1 4.873 3z" fill='#FFF'/>
                        </g>
                    </svg>
                  <h2>INTERNATIONAL CERTIFICATION</h2>
                </div>

            </div>
            <div className='section2_wrapper'>
                <div className='section2_image'>
                    <img src={section2image} alt='' />
                </div>
                <svg width="32px" height="32px" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" id='diamond1'>
                    <g>
                        <path fill="none" d="M0 0h24v24H0z"/>
                        <path d="M4.873 3h14.254a1 1 0 0 1 .809.412l3.823 5.256a.5.5 0 0 1-.037.633L12.367 21.602a.5.5 0 0 1-.734 0L.278 9.302a.5.5 0 0 1-.037-.634l3.823-5.256A1 1 0 0 1 4.873 3z" fill='#FFF'/>
                    </g>
                </svg>
                <svg width="32px" height="32px" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" id='diamond2'>
                    <g>
                        <path fill="none" d="M0 0h24v24H0z"/>
                        <path d="M4.873 3h14.254a1 1 0 0 1 .809.412l3.823 5.256a.5.5 0 0 1-.037.633L12.367 21.602a.5.5 0 0 1-.734 0L.278 9.302a.5.5 0 0 1-.037-.634l3.823-5.256A1 1 0 0 1 4.873 3z" fill='#FFF'/>
                    </g>
                </svg>
            </div>
            <div className='border_design'></div>
        </section>
        <section className='about_section3' >
            <img src={section3image} alt=''/>
            <div className='about_section3_contentmaindiv'>
                <h2>DESIGN & CRAFTMANSHIP</h2>
                <p>From the first shine to the enduring brilliance, our jewellery embodies a narrative of refined elegance, reaching your heart and uplifting your spirit. Each piece, meticulously designed, combines timeless beauty with modern comfort. Revel in the joy of wearing something that makes every moment a royal occasion, crafted for those who seek a touch of majestic distinction  </p>
            </div>
        </section>
        <section className='about_section4'>
            <div className='about_section4_contentmaindiv'>
            <div className='about_section4_headdiv'>
                <div className='section2_line'>
            <div className='diamond_line'>
                <RiVipDiamondFill className='diamond-Icon1'/>
                <hr />
            </div>
            <h4>ABOUT THE FOUNDER</h4>
            <div className='diamond_line'>
                <hr />
                <RiVipDiamondFill className='diamond-Icon2'/>
            </div>
                </div>
                    </div>
                <div className='about_section4_content2'>
                <div className='border_design'></div>
                <div className='about_section4_content'>
                <div className='about_section4_content2image'>
                        <div className='about_section4_imagediv'>
                            <img  src={mehataimage} alt=''/>
                        </div>
                    </div>
                    <div className='about_section4_content2text'>
                        <p>A leader in industry advocacy, Mr. Prashant J Mehta serves as Director of the Lab-Grown Diamond and Jewellery Promotion Council of India and has held key positions, including Past President of the Jewellers Association of Bangalore. </p>
                       <p>With over 45 years of transforming the gems and jewellery Industry, Mr. Mehta Is a pioneering force behind Tri-Dia. As the founder and former Managing Director of Rajesh Exports Ltd., a Fortune 500 company, Mr. Mehta's strategic leadership helped shape the global jewellery market. His ventures, including Rajesh Exports, process 35% of the world's gold, boasting over $37 billion in global revenue.</p>
                    </div>
                </div>


                </div>
            </div>

        </section>
            
        <section className='about_section5'>
            <div className='section2_line'>
            <div className='diamond_line'>
                <RiVipDiamondFill className='diamond-Icon1'/>
                <hr />
            </div>
            <h4>USP</h4>
            <div className='diamond_line'>
                <hr />
                <RiVipDiamondFill className='diamond-Icon2'/>
            </div>
            </div>
                <div className='about_section5_contents'>
                <div className='about_section5_contents_content'>
                        <img src={uspicon1} alt=''/>
                        <h4>SUSTAINABLE LUXURY</h4>
                    </div>
                    <div className='about_section5_contents_content'>
                    <img src={uspicon2} alt=''/>
                        <h4>INNOVATIVE CRAFTMANSHIP</h4>
                    </div>
                    <div className='about_section5_contents_content'>
                        <img src={uspicon3} alt=''/>
                        <h4>WIDE RANGE OF DIAMOND SIZES</h4>
                    </div>
                    <div className='about_section5_contents_content'>
                         <img src={uspicon4} alt=''/>
                        <h4>VALUE WITHOUT COMPROMISE</h4>
                    </div>
                </div>
                <div className='about_section5_certification'>
                    <div className='section2_line'>
            <div className='diamond_line'>
                <RiVipDiamondFill className='diamond-Icon1'/>
                <hr />
            </div>
            <h4>CERTIFICATION</h4>
            <div className='diamond_line'>
                <hr />
                <RiVipDiamondFill className='diamond-Icon2'/>
            </div>
                    </div>
                <div className='about_section5_certification_contents'>
                    <div className='about_section5_certification_contents_content'>
                        <img  src={certificateicon1} alt=''/>
                        <h4>SGL CERTIFICATION</h4>
                    </div>
                    <div className='about_section5_certification_contents_content'>
                        <img  src={certificateicon2} alt=''/>
                        <h4>IGI CERTIFICATION</h4>
                    </div>
                </div>
            </div>
        </section>

    </div>
  )
}

export default About
