import React, { useState } from 'react'
import { categorylevelimages } from '../Products';
import Categorylevel2 from './Categorylevel2';
import { useLocation } from 'react-router-dom';
import { MdAddCircle } from "react-icons/md";
import { useNavigate } from 'react-router-dom';

const Categorylevel1 = () => {
    const location=useLocation()
    let productname=location.pathname.split("/subcategory1/")[1]
    productname = productname.replace(/-/g, ' ');
    const filterditem=categorylevelimages.filter(item=>item.type===productname.toUpperCase())
    console.log(filterditem,'filterditem');
    const navigate=useNavigate()
    // let data=categorylevelimages.find(item=>item.id===props.productid)
    // console.log(data,'data');
    const [productid,setproductid]=useState(null)
  return (
    <>
        {productid ==null ?(
            <section className='categorylevel1_section1'>
                <h5>CATEGORIES</h5>
                <div className='Category_Name_div'>
                    <h3>{productname && productname}</h3>
                    <select>
                        <option value="all product">ALL PRODUCTS</option>
                    </select>
                </div>
                <div className='category_products'>
                    {filterditem && filterditem.map((item)=>(
                        //div-category_products_product- onClick={()=>setproductid(item.id)}
                    <div className='category_products_product' >
                        <img src={`${item.path}`} alt=''  />
                        <button onClick={()=>navigate(`/subcategory3/${item.id}`)} className='categorylevel2iconbutton'>
                    <MdAddCircle className='categorylevel2icon'/>
                </button>
                        <h4>{item.name}</h4>
                    </div>
                    ))}
                </div>
            </section>
        ):(
            <Categorylevel2 productid={productid}/>
        )}
    </>
  )
}

export default Categorylevel1
