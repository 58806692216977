import React, { useState } from 'react'
import Footer from './Footer';
import MenuIcon from '../Assets/icons/menuicon.svg'
import Drawer from '@mui/material/Drawer';
import { IconButton } from '@mui/material';
import closeIcon from '../Assets/icons/close.svg'
import tridialogo from '../Assets/headerimages/tridialogo.png'
import { Outlet, useNavigate } from 'react-router-dom';

const Index = () => {
    // const [content,setcontent]=useState('home')
    const [drawerOpen,setdrawerOpen]=useState(false)
    const [isArrowUp] = useState([false, false,false,false,false,false,false]);

    const navigate =useNavigate()

    // console.log(isArrowUp);
    

    // const toggleArrow = (index) => {
    //     setIsArrowUp(prevState => 
    //       prevState.map((val, i) => i === index ? !val : val)
    //     );
    //   };

  return (
    <div className=''>
        <div className='nav_main'>
            <div className='logosec'>
                <div>
                    <img src={tridialogo} alt='' className='tridialogo'/>
                </div>
            </div>
            <nav className='nav_menubar'>
                <div className='nav_menubarsubdiv1'>
                    <div className='menu_icondiv' onClick={()=>setdrawerOpen(true)}>
                        <img alt='' src={MenuIcon} className='menuicon'/>
                    </div>
                    <div className='logosec1'>
                        <div>
                          <img src={tridialogo} alt='' className='tridialogo'/>
                        </div>
                    </div>
                    <div className='nav_menu_div'>
                        <div className='nav_menu' onClick={()=>navigate('home')}>
                            <p>HOME</p>
                            {/* <IoIosArrowDown/>
                            <div className='dropdown-content'>
                                <BiSolidUpArrow className='arrowup'/>
                            </div> */}
                        </div>
                        <div className='nav_menu' onClick={()=>navigate('about')}>
                            <p>OUR STORY</p>
                            {/* <IoIosArrowDown/>
                            <div className='dropdown-content'>
                            <BiSolidUpArrow className='arrowup'/>
                            </div> */}
                        </div>
                        <div className='nav_menu'  onClick={()=>navigate('collection')}>
                            <p>OUR COLLECTION</p>
                            {/* <IoIosArrowDown/>
                            <div className='dropdown-content'>
                            <BiSolidUpArrow className='arrowuprings'/>
                            </div> */}
                        </div>
                        <div className='nav_menu' onClick={()=>navigate('category')}>
                            <p>CATEGORIES</p>
                            {/* <IoIosArrowDown/>
                            <div className='dropdown-content'>
                            <BiSolidUpArrow className='arrowuprings'/>
                            </div> */}
                        </div>
                        <div className='nav_menu' onClick={()=>navigate('blog')}>
                            <p>BLOG</p>
                            {/* <IoIosArrowDown/>
                            <div className='dropdown-content'>
                            <BiSolidUpArrow className='arrowuprings'/>
                            </div> */}
                        </div>
                        <div className='nav_menu' onClick={()=>navigate('contact')}>
                            <p>CONTACT US</p>
                            {/* <IoIosArrowDown/>
                            <div className='dropdown-content'>
                            <BiSolidUpArrow className='arrowuprings'/>
                            </div> */}
                        </div>
                        
                        <div className='nav_menu'>
                            

                        </div>
                    </div>
                </div>
            </nav>
        </div>
        <Drawer open={drawerOpen} onClose={()=>setdrawerOpen(false)}  PaperProps={{
                style: { backgroundColor: '#707070',  color:'white'}
            }} >
            <div className='drawer-container'>
                <div className='drawer_header'>
                    <IconButton onClick={()=>setdrawerOpen(false)}>
                        <img src={closeIcon} className='closeicon' alt=''/>
                    </IconButton>
                    <img src={tridialogo} alt='' style={{width:'150px',height:'30px',margin:'10px'}}/>
                </div>
                <div className='drawer_main'>
                    <div className='drawer_menu'>
                        <div className='drawer_head'>
                            <h5 onClick={()=>{
                                navigate('home')
                                setdrawerOpen(false)
                                }}>HOME</h5>
                            {/* <IconButton onClick={() => toggleArrow(0)}>
                                <img src={arrowdown} className={`arrowdown ${isArrowUp[0] ? 'rotate' : ''}`} alt=''/>
                            </IconButton> */}
                        </div>
                        <div className={isArrowUp[0] ?'drawer_contentshow':'drawer_content'}>
                            <ul>
                                <li>About Tia-Dia</li>
                            </ul>
                        </div>
                    </div>
                    <div className='drawer_menu'>
                        <div className='drawer_head'>
                            <h5  onClick={()=>{
                                navigate('about')
                                setdrawerOpen(false)
                                }}>OUR STORY</h5>
                            {/* <IconButton onClick={() => toggleArrow(1)}>
                                <img src={arrowdown} className={`arrowdown ${isArrowUp[1] ? 'rotate' : ''}`} alt=''/>
                            </IconButton> */}
                        </div>
                        <div className={isArrowUp[1] ?'drawer_contentshow':'drawer_content'}>
                            <ul>
                                <li>About Tia-Dia</li>
                            </ul>
                        </div>
                    </div>
                    <div className='drawer_menu'>
                        <div className='drawer_head'>
                            <h5  onClick={()=>{
                                navigate('collection')
                                setdrawerOpen(false)
                                }}>OUR COLLECTION</h5>
                            {/* <IconButton onClick={() => toggleArrow(2)}>
                                <img src={arrowdown} className={`arrowdown ${isArrowUp[2] ? 'rotate' : ''}`} alt=''/>
                            </IconButton> */}
                        </div>
                        <div className={isArrowUp[2] ?'drawer_contentshow':'drawer_content'}>
                            <ul>
                                <li>About Tia-Dia</li>
                            </ul>
                        </div>
                    </div>
                    <div className='drawer_menu'>
                        <div className='drawer_head'>
                            <h5  onClick={()=>{
                                navigate('category')
                                setdrawerOpen(false)
                                }}>CATEGORIES</h5>
                            {/* <IconButton onClick={() => toggleArrow(2)}>
                                <img src={arrowdown} className={`arrowdown ${isArrowUp[2] ? 'rotate' : ''}`} alt=''/>
                            </IconButton> */}
                        </div>
                        <div className={isArrowUp[2] ?'drawer_contentshow':'drawer_content'}>
                            <ul>
                                <li>About Tia-Dia</li>
                            </ul>
                        </div>
                    </div>
                    <div className='drawer_menu'>
                        <div className='drawer_head'>
                            <h5  onClick={()=>{
                                navigate('blog')
                                setdrawerOpen(false)
                                }}>BLOG</h5>
                            {/* <IconButton onClick={() => toggleArrow(2)}>
                                <img src={arrowdown} className={`arrowdown ${isArrowUp[2] ? 'rotate' : ''}`} alt=''/>
                            </IconButton> */}
                        </div>
                        <div className={isArrowUp[2] ?'drawer_contentshow':'drawer_content'}>
                            <ul>
                                <li>About Tia-Dia</li>
                            </ul>
                        </div>
                    </div>
                    <div className='drawer_menu'>
                        <div className='drawer_head'>
                            <h5  onClick={()=>{
                                navigate('contact')
                                setdrawerOpen(false)
                                }}>CONTACT US</h5>
                            {/* <IconButton onClick={() => toggleArrow(3)}>
                                <img src={arrowdown} className={`arrowdown ${isArrowUp[3] ? 'rotate' : ''}`} alt=''/>
                            </IconButton> */}
                        </div>
                        <div className={isArrowUp[3] ?'drawer_contentshow':'drawer_content'}>
                            <ul>
                                <li>About Tia-Dia</li>
                            </ul>
                        </div>
                    </div>
                    {/* <div className='drawer_menu'>
                        <div className='drawer_head'>
                            <h5>Occasions</h5>
                            <IconButton onClick={() => toggleArrow(4)}>
                                <img src={arrowdown} className={`arrowdown ${isArrowUp[4] ? 'rotate' : ''}`}/>
                            </IconButton>
                        </div>
                        <div className={isArrowUp[4] ?'drawer_contentshow':'drawer_content'}>
                            <ul>
                                <li>About Tia-Dia</li>
                            </ul>
                        </div>
                    </div>
                    <div className='drawer_menu'>
                        <div className='drawer_head'>
                            <h5>Goldern Programs</h5>
                            <IconButton onClick={() => toggleArrow(5)}>
                                <img src={arrowdown} className={`arrowdown ${isArrowUp[5] ? 'rotate' : ''}`}/>
                            </IconButton>
                        </div>
                        <div className={isArrowUp[5] ?'drawer_contentshow':'drawer_content'}>
                            <ul>
                                <li>About Tia-Dia</li>
                            </ul>
                        </div>
                    </div>
                    <div className='drawer_menu'>
                        <div className='drawer_head'>
                            <h5>Store</h5>
                            <IconButton onClick={() => toggleArrow(6)}>
                                <img src={arrowdown} className={`arrowdown ${isArrowUp[6] ? 'rotate' : ''}`}/>
                            </IconButton>
                        </div>
                        <div className={isArrowUp[6] ?'drawer_contentshow':'drawer_content'}>
                            <ul>
                                <li>About Tia-Dia</li>
                            </ul>
                        </div>
                    </div>
                    <div className='drawer_menu'>
                        <div className='d-flex ml-2 mt-3'>
                            <h5 style={{cursor:'pointer'}} onClick={()=>setcontent('about')}>About</h5>
                        </div>
                    </div>
                    <div className='drawer_menu'>
                        <div className='d-flex ml-2 mt-3'>
                            <h5 style={{cursor:'pointer'}} onClick={()=>setcontent('home')}>Home</h5>
                        </div>
                    </div> */}
                </div>
            </div>
        </Drawer>
            <Outlet/>
        <Footer/>   
    </div>
  )
}

export default Index
