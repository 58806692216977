import React from 'react'
import { categorylevelimages } from '../Products';
import { MdAddCircle } from "react-icons/md";
import { useNavigate } from 'react-router-dom';

const Categorylevel2 = (props) => {
    const navigate=useNavigate()
    console.log(props,'props');
    let data=categorylevelimages.find(item=>item.id===props.productid)
    console.log(data,'data');
    
    
  return (
    <div className='categorylevel2_main'>
        <section className='categorylevel2_section1'>
            <div className='categorylevel2_section1sub1'>
                <img src={`${data.path}`} alt=''/>
                <button onClick={()=>navigate(`/subcategory3/${data.id}`)} className='categorylevel2iconbutton'>
                    <MdAddCircle className='categorylevel2icon'/>
                </button>
            </div>
            <div className='categorylevel2_section1sub2'>
                <h4>{data.name}</h4>
                <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Quae ex vitae reprehenderit fugiat sapiente esse magnam odit enim incidunt? Accusantium voluptas aliquid quod, magni dolor tempore saepe ducimus officia nesciunt.</p>
                <button className='collection_button' onClick={()=>window.location.href='https://wa.me/919164326222'}>CONTACT US</button>
                <p>SKU:000</p>
                <p>Category:jhumka</p>
            </div>
        </section>
    </div>
  )
}

export default Categorylevel2
