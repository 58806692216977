import React, { useEffect, useState } from 'react'
import Arrowright from '../../Assets/icons/arrowright.svg'
import Arrowleft from '../../Assets/icons/arrowleft.svg'
import { IconButton } from '@mui/material'
import { products } from '../Products'
import { useLocation, useNavigate, useParams } from 'react-router-dom'

const CollectionViewProduct = (props) => {
  const navigate=useNavigate()
  const location=useLocation()
  const {id}=useParams()
  // console.log(pathid,'pathid');
   
  const [path,setpath]=useState()
  const [displayimage,setdisplayimage]=useState(0)

  // console.log(path,'path');
  

  useEffect(()=>{
    const pathimage=products.find(item=>item.id==id)
    setpath(pathimage)
  },[id])

  const clickRightButton=()=>{
    if (displayimage === path.subimage.length-1) {
      setdisplayimage(0);
    } else if (displayimage >= 0 && displayimage < path.subimage.length-1) {
      setdisplayimage(displayimage + 1);
    }
  }
  

  const clickLeftButton=()=>{
    if(displayimage===0){
      setdisplayimage(path.subimage.length-1)
    }
    else if (displayimage > 0 && displayimage <= path.subimage.length) {
      setdisplayimage(displayimage - 1);
    } 
  }

  const handlebackclick = () => {
    // Navigate back to the home component with pathid as state or query params
    const path = location.state?.from;
    if(path=='home'){
      navigate('/home', { state: { id } }); // Send pathid to home
    }
    else{
      navigate('/collection', { state: { id } }); // Send pathid to home

    }
    // You can also use query params like navigate(`/home?pathid=${pathid}`);
  };
  
  return (
<section className='collectionview_section1_main'>
      
<div>
<div className='collectionview_section1_submain'>
<IconButton className='home_iconbutton' id='collection_back_btn' onClick={handlebackclick} >
          <img src={Arrowleft}  alt='' />
       </IconButton>
      <div className='collectionview_section1_sub1'>
        <div>
          <IconButton className='collection_iconbtn'>
                <img src={Arrowleft} onClick={clickLeftButton} alt=''/>
        </IconButton>
        </div>
          <img src={`${path && path.subimage[displayimage].img}`} alt='' className='collectionlargeimage'/>
        <div>
          <IconButton className='collection_iconbtn' onClick={clickRightButton}>
              <img src={Arrowright} alt=''/>
        </IconButton>
        </div>
      </div>
      <div  className='collectionview_section1_sub2'>
        {path && path.subimage && path.subimage.map((item)=>(
        <div>
            <img src={`${item.img}`} alt=''  className='collectionsmallimage'/>
        </div>
        ))}
      </div>
    </div>
</div>



    {/* <div className='product_image_nav'>
    <IconButton className='home_iconbutton'>
            <img src={Arrowleft} onClick={clickLeftButton} alt=''/>
    </IconButton>
    <IconButton className='home_iconbutton' onClick={clickRightButton}>
            <img src={Arrowright} alt=''/>
    </IconButton>
    </div> */}

    </section>

  )
}

export default CollectionViewProduct
