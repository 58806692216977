import React, {useEffect, useRef} from 'react'
import tridialogo from '../Assets/headerimages/tridialogo.png'
import { useNavigate } from 'react-router-dom';


const Footer = () => {
    const navigate =useNavigate()
    // const [isArrowUp] = useState([false, false,false,false,false,false,false]);
    const scrollToTopAndNavigate = (path) => {
        window.scrollTo(0, 0); // Scroll to the top
        navigate(path); // Navigate to the desired route
    };


    const svgRef = useRef(null); // Create a ref for the SVG element
  useEffect(() => {
    // Function to log the BBox of the SVG in viewBox format
    const logBBox = () => {
      if (svgRef.current) {
        const bbox = svgRef.current.getBBox(); // Get the bounding box
        const viewBoxValue = `${bbox.x} ${bbox.y} ${bbox.width} ${bbox.height}`;
        console.log(`viewBox="${viewBoxValue}"`); // Log the viewBox value in the correct format
      }
    };

    
        logBBox(); // Call the function when the component mounts
      }, []); // Empty dependency array to ensure it runs once after mount
    

    // const toggleArrow = (index) => {
    //     setIsArrowUp(prevState => 
    //       prevState.map((val, i) => i === index ? !val : val)
    //     );
    //   };

  return (
    <div className='footer_container'>
        <div className='footer_main'>
            <div className='footer_header'>
                <img src={tridialogo} alt='' className='tridiafooterlogo' onClick={()=>scrollToTopAndNavigate('home')}/>
            </div>
            <div className='footer_content'>
            <div className='footer_address'>
            <p>27/75/3, SOUTH, N PARK Rd, Nehru Nagar, Seshadripuram, Bengaluru, karnataka 560020</p>
            </div>
            <div className='footer_contact'>
                <a href='tel:9164324111'>9164324111</a>
                <a href='tel:9164326222'>9164326222</a>
                <a href='mailto:support@tridia.in'>support@tridia.in</a>
            </div>
            <div className='footer_navdetails'>
                <button onClick={() => scrollToTopAndNavigate('home')}>Home</button>
                <button onClick={() => scrollToTopAndNavigate('about')}>Our Story</button>
                <button onClick={() => scrollToTopAndNavigate('collection')}>Our Collection</button>
                <button onClick={() => scrollToTopAndNavigate('category')}>Categories</button>
                <button onClick={() => scrollToTopAndNavigate('blog')}>Blog</button>
                <button onClick={() => scrollToTopAndNavigate('contact')}>Contact Us</button>
            </div>
            </div>

            <div className='social_media_div'>
                <a href='https://www.instagram.com/tridia.in/'>
                <svg xmlns="http://www.w3.org/2000/svg" viewBox='0.7420001029968262 0 38.742000579833984 38.6510009765625' id="instagram">
                    <path d="M33.212 38.651H7.015c-3.459 0-6.273-2.808-6.273-6.26V6.26C.742 2.808 3.556 0 7.015 0h26.197c3.458 0 6.272 2.808 6.272 6.26v26.132c0 3.452-2.814 6.259-6.272 6.259ZM13.742 8h13a5 5 0 0 1 5 5v13a5 5 0 0 1-5 5h-13a5 5 0 0 1-5-5V13a5 5 0 0 1 5-5Zm0-3a8 8 0 0 0-8 8v13a8 8 0 0 0 8 8h13a8 8 0 0 0 8-8V13a8 8 0 0 0-8-8h-13Zm6.5 21.5a7 7 0 1 0 0-14 7 7 0 0 0 0 14Zm0-3a4 4 0 1 1 0-8 4 4 0 0 1 0 8Zm7.5-9.5a2 2 0 1 0 0-4 2 2 0 0 0 0 4Z" fill='#FFF'>                        
                    </path>
                </svg>

                </a>
                <a href='https://wa.me/919164326222'>
                    <svg viewBox='128 128 1536 1536' xmlns="http://www.w3.org/2000/svg"  id="facebook">
                        <path d="M1376 128q119 0 203.5 84.5T1664 416v960q0 119-84.5 203.5T1376 1664h-188v-595h199l30-232h-229V689q0-56 23.5-84t91.5-28l122-1V369q-63-9-178-9-136 0-217.5 80T948 666v171H748v232h200v595H416q-119 0-203.5-84.5T128 1376V416q0-119 84.5-203.5T416 128h960z" fill='#FFF'>                            
                        </path>
                    </svg>

                </a>
                <a href='https://wa.me/919164326222'> 
                    <svg viewBox='0 0 38.742000579833984 38.6510009765625' xmlns="http://www.w3.org/2000/svg" id="whatsapp">
                        <path d="M32.47 38.651H6.273C2.814 38.651 0 35.843 0 32.391V6.26C0 2.808 2.814 0 6.273 0H32.47c3.458 0 6.272 2.808 6.272 6.26v26.132c0 3.452-2.814 6.259-6.272 6.259ZM13.73 27.568l-5.282 1.368 1.978-5.17-.194-.423a10.502 10.502 0 0 1 2.661-12.293c4.376-3.804 11.006-3.34 14.81 1.035 3.804 4.376 3.34 11.006-1.036 14.81a10.502 10.502 0 0 1-12.544.924l-.392-.251Zm-2.559-18.5A13.127 13.127 0 0 0 7.656 24l-2.704 7.976 8.163-1.696a13.127 13.127 0 0 0 15.274-1.403c5.47-4.755 6.05-13.043 1.295-18.513C24.929 4.893 16.64 4.314 11.17 9.07Zm14.073 12.833-.2-.118c-.53-.33-1.72-1.039-1.956-1.145-.484-.23-.847-.22-1.193.215-.158.2-.596.645-.986 1.022-.034.033.01.031-.1-.038-1.94-1.191-2.691-1.919-3.494-3.618.147-.127.618-.584.87-.961.033-.05.066-.1.097-.151.18-.3.174-.594.053-.902-.197-.498-1.837-3.097-2.246-3.133a1.513 1.513 0 0 0-1.141.406c.022-.02-.165.15-.232.213a3.97 3.97 0 0 0-.323.346c-.45.546-.744 1.218-.819 2.07-.066.755.112 1.551.452 2.352.178.418.318.675.533 1.03-.12-.188.316.544.56.914.41.622.876 1.237 1.406 1.823.847.937 1.778 1.71 2.794 2.26 1.709.922 2.537 1.155 3.668 1.087.846-.051 2.116-.718 2.458-1.47.31-.682.44-1.429.283-1.747-.05-.122-.15-.23-.294-.333a2.682 2.682 0 0 0-.19-.122Z" fill='#FFF'>                            
                        </path>
                    </svg>

                </a> 
            </div>
        </div>
        <div className="footer_second_horizontal_line"></div>
        <div className="footer_first_horizontal_line"></div>
        <div className="footer_copyrightdiv">
            <p>COPYRIGHT @ TRIDIA 2024 - ALL RIGHTS RESERVED</p>
        </div>
    </div>
  )
}

export default Footer
