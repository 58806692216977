import React, { useState } from 'react'
import Bloglevel1 from './Blog/Bloglevel1'
// import img1 from '../Assets/category/7.jpg'
import { blogcontent } from './Products'

const Blog = () => {
    const [product,setproduct]=useState(null)
    
  return (
    <div className='blog'>
                        <h3>BLOGS</h3>

        {product==null ?(
         blogcontent && blogcontent.map(item=>(
        <div className='blog_container'>

            <div className='blog_content_main' id='blog1'>
                <div className='blog_img_container'><img alt='' src={item.path}/></div>
                <div className='blog_content'>
                    <h2>{item.mainheading}</h2>
                    {item.maincontent.map((para,index)=>(
                    <p key={index}>{para}</p>
                    ))}
                    <button className='collection_button' onClick={()=>setproduct(1)}>VIEW MORE</button>
                </div>
            </div>
            {/* <div className='blog_content_main' id='blog2'>
                <div className='blog_content'>
                    <h2>Lorem ipsum dolor sit,<br/> amet consectetur adipisicing elit.</h2>
                    <p>Lorem ipsum dolor sit, amet consectetur adipisicing elit. Dolor, consequatur soluta inventore, laboriosam fuga reprehenderit alias ad sed, eaque velit sint recusandae accusamus architecto minus maiores corporis mollitia illo doloribus!</p>
                    <button className='collection_button' onClick={()=>setproduct(1)}>VIEW MORE</button>
                </div>
                <div className='blog_img_container'><img alt='' src={img1}/></div>
            </div> */}
            {/* <div className='blog__content3_main'>
                <div className='blog__content1_sub1'><img alt='' src={img1}/></div>
                <div className='blog__content1_sub2'>
                    <h2>Lorem ipsum dolor sit,<br/> amet consectetur adipisicing elit.</h2>
                    <p>Lorem ipsum dolor sit, amet consectetur adipisicing elit. Dolor, consequatur soluta inventore, laboriosam fuga reprehenderit alias ad sed, eaque velit sint recusandae accusamus architecto minus maiores corporis mollitia illo doloribus!</p>
                    <button className='collection_button' onClick={()=>setproduct(3)}>VIEW MORE</button>
                </div>
            </div> */}
        </div>
         ))
        ):(
          <Bloglevel1 product={product}/>
        )}
    </div>
  )
}

export default Blog
